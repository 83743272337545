<template>
  <h1>This will be the credits and thank you information.</h1>
  <p>
    You can read the doctoral thesis that accompanies this work
    <a href="https://thesis.adamprocter.co.uk">here</a>
  </p>
</template>

<script>
export default {
  name: 'Credits',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-size: 3em;
}
</style>
